import React, { useState, useEffect } from "react";
import { Table, Pagination, Input, Button } from "antd";
import {
  SysDateTransform,
  SysGenValueOption,
  SysJWTDecoder,
  SysMonthTransform,
  addZero,
  showToast,
} from "../../utils/global_store";
import Select from "react-select";
import { sys_images, sys_labels } from "../../utils/constants";
import { useLoadingContext } from "../Loading";
import * as XLSX from "xlsx-js-style";
import { SearchIcon } from "../../assets";
// import "xlsx/dist/"
const { Search } = Input;

const DataTablePaginationReport = ({
  fetchDataFunc,
  columns,
  pageSizeOptions = ["10", "20", "30"],
  defaultPageSize = 10,
  withPeriode = false,
  title = "",
  filters = [],
  action = [],
  withExport = true,
}) => {
  const { showLoading, hideLoading } = useLoadingContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalItems, setTotalItems] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilters] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const date = new Date();
  const [periode, setPeriode] = useState({
    month: date.getMonth(),
    year: date.getFullYear(),
  });
  const month_data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let year_data = [];
  for (
    let index = date.getFullYear();
    index > date.getFullYear() - 5;
    index--
  ) {
    year_data.push(index);
  }

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchQuery, sortField, sortOrder, filter]);

  const fetchData = () => {
    let sort = `${sortField}:${sortOrder == "ascend" ? "asc" : "desc"}`;
    if (sortField == "" || sortField == null || sortField == undefined) {
      let sort = "";
    }
    const my_filter = genFilter();
    fetchDataFunc(currentPage, pageSize, searchQuery, sort, my_filter)
      .then((data) => {
        console.log(data);
        let my_data = data.data;
        let the_datas = [];
        for (let index = 0; index < my_data.length; index++) {
          let clean_data = {};
          Object.keys(my_data[index]).map((key) => {
            if (
              typeof my_data[index][key] === "object" &&
              my_data[index][key] != null
            ) {
              Object.keys(my_data[index][key]).map((key_child) => {
                clean_data[`${key}_${key_child}`] =
                  my_data[index][key][key_child] ?? "";
              });
            } else {
              clean_data[key] = my_data[index][key] ?? "";
            }
          });
          the_datas.push(clean_data);
        }
        setTableData(the_datas);
        setTotalItems(data.totalData * pageSize);
      })
      .catch((error) => {
        console.log(error);
        showToast({ message: error.message, type: "error" });
        console.error("Error fetching data:", error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
  };

  const handleSearch = (value) => {
    console.log(value);
    // setCurrentPage(1);

    setSearchQuery(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);
  };

  const handleChange = (value, index) => {
    let my_filter = selectedFilters;
    let my_filter_str = "";
    my_filter[index] = value.value;
    Object.keys(my_filter).map((val) => {
      if (my_filter[val] != "all") {
        my_filter_str += `&${val}=${my_filter[val]}`;
      }
    });
    if (withPeriode) {
      my_filter_str += `&periode=${periode.year}-${addZero({
        num: parseInt(periode.month) + 1,
      })}`;
    }

    if (value.value == "all") {
      delete my_filter[index];
    }
    setSelectedFilters(my_filter);
    setFilters(my_filter_str);
  };

  // const handleChangePeriode = (event) => {
  //   let my_filter = selectedFilters;
  //   const { name, value } = event.target;
  //   let my_filter_str = "";
  //   let my_periode = periode;
  //   my_periode[name] = value;
  //   Object.keys(my_filter).map((val) => {
  //     if (my_filter[val] != "all") {
  //       my_filter_str += `&${val}=${my_filter[val]}`;
  //     }
  //   });
  //   my_filter_str += `&periode=${my_periode.year}-${addZero({
  //     num: parseInt(my_periode.month) + 1,
  //   })}`;
  //   setFilters(my_filter_str);
  // };

  const handleChangePeriode = (event) => {
  let my_filter = selectedFilters;
  const { name, value } = event.target;
  let my_filter_str = "";
  let my_periode = { ...periode, [name]: value };
  Object.keys(my_filter).map((val) => {
    if (my_filter[val] != "all") {
      my_filter_str += `&${val}=${my_filter[val]}`;
    }
  });
  my_filter_str += `&periode=${my_periode.year}-${addZero({
    num: parseInt(my_periode.month),
  })}`;
  setPeriode(my_periode);
  setFilters(my_filter_str);
};

  const genFilter = () => {
    let my_filter = selectedFilters;
    let my_filter_str = "";
    let my_periode = periode;
    Object.keys(my_filter).map((val) => {
      if (my_filter[val] != "all") {
        my_filter_str += `&${val}=${my_filter[val]}`;
      }
    });
    my_filter_str += `&periode=${my_periode.year}-${addZero({
      num: parseInt(my_periode.month) + 1,
    })}`;
    setFilters(my_filter_str);
    return my_filter_str;
  };

  const FilterComponent = () => {
    return (
      <div className="d-flex flex-row" style={{ alignItems: "center" }}>
        {filters.map((val) => {
          let data = [
            {
              value: "all",
              label: `Semua ${val?.title ?? ""}`,
            },
          ];
          if (val.data && val.data.length > 0) {
            val.data.map((value) => {
              data.push({
                value: value[val.data_id],
                label: value[val.label],
              });
            });
          }
          return (
            <div className="form-group" style={{ marginRight: 10 }}>
              <Select
                styles={{
                  menu: (provide, state) => ({
                    ...provide,
                    zIndex: 10,
                  }),

                  control: (provide) => ({
                    ...provide,
                    borderRadius: 15,
                    height: 45,
                    minWidth: 250,
                  }),
                }}
                onChange={(value) => handleChange(value, val.index)}
                value={SysGenValueOption(
                  val.data,
                  selectedFilters[val.index],
                  val.data_id,
                  val.label
                )}
                options={data}
                formatOptionLabel={(val) => `${val.label}`}
                placeholder={`Pilih ${val?.title ?? ""}`}
                aria-label="Nama"
                isSearchable
              />
            </div>
          );
        })}

        {withPeriode && (
          <>
            <div className="form-group" style={{ marginRight: 10 }}>
              <select
                className="form-select btn-aditional-no-hover"
                id="year"
                name="year"
                placeholder="Pilih Tahun"
                value={periode.year}
                onChange={handleChangePeriode}
                aria-label="year"
              >
                <option value={null}>Pilih Periode Tahun</option>
                {year_data.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group" style={{ marginRight: 10 }}>
              <select
                className="form-select btn-aditional-no-hover"
                id="month"
                placeholder="Pilih Bulan"
                name="month"
                value={periode.month}
                onChange={handleChangePeriode}
                aria-label="Month"
              >
                <option value={null}>Pilih Periode Bulan</option>
                {month_data.map((option, index) => (
                  <option key={index} value={option}>
                    {SysMonthTransform(option, "long", "in")}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group" style={{ marginRight: 10 }}>
            <button
              onClick={handleExportDetail}
              className="btn btn-primary"
              style={{ height: '30px', padding: '0 20px' }}
            >
              Export
            </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleExportDetail = async () => {
  showLoading();
  try {
    let selectedPeriode = periode;
    if (!selectedPeriode.year || !selectedPeriode.month) {
      const now = new Date();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      selectedPeriode = { year, month };
    }


      // Pastikan selectedPeriode.month adalah integer sebelum menambah 1
      const month = parseInt(selectedPeriode.month, 10) + 1;
      const formattedPeriode = `${selectedPeriode.year}-${String(month).padStart(2, '0')}`;

      const response = await fetch(`https://jakhr-api.jakpro.co.id/api/v1/reports/payroll_report?periode=${formattedPeriode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`, // Pastikan token disimpan di localStorage
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.datas) {
        exportToExcel(result.datas, formattedPeriode);
      } else {
        alert("Tidak ada data untuk periode yang dipilih.");
      }
    } catch (error) {
      console.error("Error fetching payroll report:", error);
      alert("Terjadi kesalahan saat mengambil data.");
    }
    hideLoading();
  };

  const exportToExcel = (data, printedPeriode) => {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const decodedToken = SysJWTDecoder(token);
  const companyName = decodedToken.company; // Asumsikan nama perusahaan ada di properti 'company' dari token

  const worksheetData = data.map(item => {
    const payrollDetails = item.payrollDetails[0];
    return {
      "Nama Lengkap": item.full_name,
      "Employee ID": item.employee_id,
      "Gaji Bersih": payrollDetails.final_salary,
      "Gaji Pokok": payrollDetails.base_salary,
      "Total Kehadiran": payrollDetails.total_attendance,
      "Total Hari Kerja per Bulan": payrollDetails.total_workday_per_month,
      "Pajak Dibayar Perusahaan": payrollDetails.tax_paid_by_company,
      "Lembur": payrollDetails.overtimes,
      "Pajak": payrollDetails.tax,
      "Denda Keterlambatan": payrollDetails.late_penalty,
      "Tunjangan Tetap": JSON.parse(payrollDetails.fix_allowances).total,
      "Tunjangan Harian": JSON.parse(payrollDetails.daily_allowances).total,
      "Tunjangan Tidak Tetap": JSON.parse(payrollDetails.not_fix_allowances).total,
      "Insentif": JSON.parse(payrollDetails.incentives).total,
      "Kas Bon": JSON.parse(payrollDetails.cash_advances).total,
      "Asuransi JHT": JSON.parse(payrollDetails.insurances).jht_by_employee,
      "Asuransi Kesehatan": JSON.parse(payrollDetails.insurances).kesehatan_by_employee,
      "Asuransi JP": JSON.parse(payrollDetails.insurances).jp_by_employee,
      "Asuransi Lainnya": JSON.parse(payrollDetails.insurances).other_insurance_by_employee,
      "Potongan Tetap": JSON.parse(payrollDetails.fix_deductions).total,
      "Potongan Tidak Tetap": JSON.parse(payrollDetails.not_fix_deductions).total,
    };
  });

  const worksheet = XLSX.utils.json_to_sheet([]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Report");

  // Menambahkan informasi tambahan di bagian paling atas
  const currentDate = new Date().toLocaleDateString("id-ID", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  XLSX.utils.sheet_add_aoa(worksheet, [
    [companyName],
    [`Periode: ${printedPeriode}`],
    [`Tanggal Dibuat: ${currentDate}`],
  ], { origin: "A1" });

  // Menambahkan data detail mulai dari baris ke-5
  XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: "A5", skipHeader: false });

  XLSX.writeFile(workbook, `Payroll_Report_${printedPeriode}.xlsx`);
};

  const handleExport = async () => {
    showLoading();
    try {
      let sort = `${sortField}:${sortOrder == "ascend" ? "asc" : "desc"}`;
      if (sortField == "" || sortField == null || sortField == undefined) {
        sort = "";
      }
      const resp = await fetchDataFunc(1, 99999999, searchQuery, sort, filter);
      let str_filter = "";

      if (withPeriode) {
        str_filter += `Periode: ${SysMonthTransform(periode.month, "in")} ${
          periode.year
        }, `;
      }
      Object.keys(selectedFilters).map((value) => {
        const obj = filters.find((val) => val.index == value);
        const data_selected = obj.data.find(
          (val) => val[obj.data_id] == selectedFilters[value]
        );
        str_filter += `${obj.title}: ${data_selected[obj.label]}, `;
      });
      let my_data = resp.data;
      let the_datas = [];
      for (let index = 0; index < my_data.length; index++) {
        let clean_data_structure = {};
        Object.keys(my_data[index]).map((key) => {
          if (
            typeof my_data[index][key] === "object" &&
            my_data[index][key] != null
          ) {
            Object.keys(my_data[index][key]).map((key_child) => {
              clean_data_structure[`${key}_${key_child}`] =
                my_data[index][key][key_child];
              // console.log(clean_data_structure);
            });
          } else {
            clean_data_structure[key] = my_data[index][key];
          }
        });
        the_datas.push(clean_data_structure);
      }
      let clean_data = [];
      // let col_length=0;
      the_datas.map((val) => {
        let obj_data = {};
        columns.map((col_value) => {
          if (col_value.key != "action") {
            obj_data[col_value.title] = val[col_value.key] ?? "";
            if (col_value.val_props) {
              obj_data[col_value.title] =
                col_value.val_props[val[col_value.key]];
            }
          }
        });
        clean_data.push(obj_data);
      });
      const ws = XLSX.utils.json_to_sheet(clean_data, { origin: "A5" });
      const wb = XLSX.utils.book_new();
      const headerStyle = {
        alignment: { horizontal: "center", vertical: "center" },
        font: { sz: 12, bold: true },
      };
      for (
        let colIndex = 0;
        colIndex < columns.filter((val) => val.key != "action").length;
        colIndex++
      ) {
        // console.log(colIndex);
        const cellRef = XLSX.utils.encode_cell({ r: 4, c: colIndex }); // Row 5 is index 4
        ws[cellRef].s = headerStyle; // Apply the style to the cell
      }
      ws["!merges"] = [
        {
          s: { r: 0, c: 0 },
          e: {
            r: 0,
            c: columns.filter((val) => val.key != "action").length - 1,
          },
        },
      ];
      ws["A1"] = {
        t: "s",
        v: `Laporan ${title}`,
      };
      ws["A1"].s = {
        alignment: { horizontal: "center", vertical: "center" },
        font: { sz: 20, bold: true },
      };
      const token = SysJWTDecoder();
      ws["A2"] = { t: "s", v: "Exported Date: " };
      ws["A3"] = { t: "s", v: "Exported By: " };
      ws["B3"] = { t: "s", v: token.full_name };
      ws["A4"] = { t: "s", v: "Filtered: " };
      ws["B4"] = { t: "s", v: str_filter };
      ws["B2"] = {
        t: "s",
        v: SysDateTransform({
          date: date,
          type: "long",
          lang: "in",
          withTime: true,
        }),
      };
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        sys_labels.menus.REPORT + " " + title + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      showToast({ message: error.message });
    }
    hideLoading();
  };
  return (
    <section className="section">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4>{title}</h4>
          <div className="d-flex flex-row">
            {action}
            {withExport && (
              <Button
                onClick={handleExport}
                className="btn btn-primary btn-aditional"
              >
                {sys_labels.action.EXPORT_EXCEL}
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div
            style={{
              overflow: "hidden",
              minHeight: "750px",
            }}
          >
            <div className="col-md-12">
              <FilterComponent />
            </div>
            <>
              <Input
                placeholder="Search..."
                allowClear
                prefix={<SearchIcon />}
                style={{ flex: 1, marginBottom: 10 }}
                className="btn-height-border"
                onChange={(val) => handleSearch(val.target.value)}
              />
            </>

            <Table
              dataSource={tableData}
              pagination={false}
              sticky={true}
              className="table-responsive"
              locale={{
                emptyText: (
                  <img src={sys_images.img_empty} height={200} width={300} />
                ),
              }}
              onChange={handleTableChange}
              columns={columns
                .filter((val) => val.type != "hidden")
                .map((col) => ({
                  ...col,
                  sorter:
                    col.key === "action"
                      ? false
                      : !col.sortable
                      ? false
                      : (a, b) => a[col["key"]].localeCompare(b[col["key"]]),
                }))}
              style={{ marginBottom: 30, height: "550px" }}
            />

            <Pagination
              style={{ position: "absolute", bottom: 15, right: 15 }}
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageSizeChange}
              pageSizeOptions={pageSizeOptions}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataTablePaginationReport;
